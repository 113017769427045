import { BgVariants } from "@9amhealth/wcl";
import styled from "@emotion/styled";
import clsx from "clsx";
import React from "react";

const BackgroundStyle = styled.div`
  background-color: var(--color-cream);
  min-height: 100%;

  &.animate-primary {
    background: var(
      --bg-primary-gradient,
      linear-gradient(228deg, #ffdfb4 -16.15%, #fddee9 40.76%, #c1d7fb 94.22%)
    );
  }

  &.bg-fixed {
    background-attachment: fixed;
    background-size: 100sw 100wh;
    background-size: 100svw 100svh;
    background-position: center;
  }
`;

const WithBackground: React.FC<{
  children: React.ReactNode;
  variant?: BgVariants;
  fixed?: boolean;
  style?: React.CSSProperties;
}> = ({ children, variant, fixed, style }) => {
  return (
    <BackgroundStyle
      className={clsx(variant, "bg-wrapper", {
        "bg-fixed": fixed
      })}
      style={style}
    >
      {children}
    </BackgroundStyle>
  );
};

export default WithBackground;
